import { Text, Box, Heading, Grid, Divider, Card, Image } from 'theme-ui'
import defaultIMG from '../../assets/default.gif'

const DefautlCard = () => (
  <Grid gap={6} columns={['1fr', '1fr 1fr 1fr']}>
    <Card
      variant="nft"
      sx={{
        border: '1px solid white',
        boxShadow: '-4px -4px 0 0 #F46B9B, 4px 4px 0 0 #72D2AF',
        padding: '1rem',
        margin: '1rem',
        backgroundColor: 'black',
      }}
    >
      <Image
        sx={{
          width: '100%',
          bg: 'black',
        }}
        src={defaultIMG}
      />
      <Box p={3} pt={2} sx={{ bg: '#f0f0f0', color: 'white', backgroundColor: 'black' }}>
        <Heading as="h2" sx={{ fontFamily: 'VCR OSD MONO', fontWeight: 'bolder' }}>
          ???
        </Heading>
        <Divider variant="divider.nft" />
      </Box>
    </Card>
  </Grid>
)
export default DefautlCard
