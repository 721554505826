import styled, { css } from 'styled-components'
import { Box, Flex, Link } from 'theme-ui'

type ItemProps = {
  isOpen?: boolean
}

export const Navbar = styled(Box)`
  ${({ theme }) => css`
    width: auto;
    display: flex;
    position: fixed;
    z-index: 3;
  `}
`
export const MenuSection = styled(Flex)`
  display: flex;
  align-items: center;
  text-align: center;
`
export const MenuLink = styled(Link)`
  color: #fff !important;
  padding-right: 2rem;
  font-family: 'VCR OSD MONO';
`

export const Item = styled(Box)<ItemProps>`
  list-style: none;
  cursor: pointer;
  text-transform: uppercase;
`
export const MenuItem = styled(Item)`
  ${({ isOpen }) => css`
    display: ${isOpen ? 'flex!important' : 'none'};
    padding-bottom: ${isOpen ? '2rem' : 0};
    padding-top: ${isOpen ? '.7rem' : 0};
  `}
`
export const MenuWrapper = styled(Box)`
  @media only screen and (min-width: 1280px) and (max-width: 2400px) {
    margin-right: 15rem !important;
  }
`
export const Wrapper = styled(Flex)<{ isOpen?: any }>`
  ${({ theme, isOpen }) => css`
    ${!isOpen ? 'height: 4rem' : 'height: 20rem!important'};
    align-content: ${!isOpen ? 'start' : 'flex-start'};
    transition: all 0.2s ease-out;
    width: 100%;
    background-color: ${theme.colors.dark};
    border-radius: 20px;
    box-shadow: rgb(215 215 215 / 14%) 3px 3px;
  `};
`
