import { Flex, Box, Label, Image, Grid } from '@theme-ui/components'
import styled from 'styled-components'
import card from '../../assets/cards.png'
import ContainerToe from './ContainerToe'
import WhiteHead from '../../assets/headToe.png'
import HeadToe from './HeadToe'
import { TOES_ARMY } from '../../utils/constants'

const ContainerCard = styled(Box)`
  position: relative;
  display: flex;
  background-image: url(${card});
  background-size: cover;
`

const Wrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0px) !important;
`

const JoinSection = () => {
  return (
    <Flex
      sx={{
        margin: ['2rem', '4rem', '4rem'],
        flexDirection: 'column',
        position: 'relative',
        maxWidth: [null, null, null, null, '1600px'],
      }}
    >
      <Box sx={{ marginBottom: '3rem' }}>
        <Label
          sx={{
            fontSize: '48px',
            color: '#161616',
            fontWeight: 'bolder',
            fontFamily: 'VCR OSD MONO',
          }}
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0"
        >
          JOIN THE ONE EYED ARMY...
        </Label>
      </Box>
      <Box>
        <Box
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          sx={{ position: 'relative' }}
        >
          <HeadToe
            head={WhiteHead}
            style={{
              width: '5%',
              left: 'auto',
              right: '0',
              bottom: '0',
              '@media screen and (max-width: 639px)': {
                display: 'none',
              },
            }}
          />
        </Box>
        <Grid gap={5} columns={[2, 4, 4]} sx={{ textAlign: 'center', placeItems: 'center' }}>
          {TOES_ARMY.map(({ toe, head, style, aos, toeName, rarity }, index) => (
            <ContainerCard
              data-aos="zoom-in-up"
              key={index}
              sx={{
                width: ['8rem', '8rem', '10.5rem', '13.2rem', '16.2rem'],
                height: ['10.5rem', '10.3rem', '13.6rem', '17rem', '21rem'],
              }}
            >
              <ContainerToe toe={toe} />
              <Wrapper
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  bottom: ['0.8rem', '0', '.5rem', '1rem'],
                  width: '100%',
                  padding: ['0.5rem', '0.6rem', '1.3rem'],
                }}
              >
                <Flex sx={{ flexDirection: 'column', display: 'flex' }}>
                  <Label
                    sx={{
                      fontSize: ['10px', '10px', '12px', '15px'],
                      whiteSpace: ['nowrap'],
                      fontWeight: 'bolder',
                      fontFamily: 'VCR OSD MONO',
                      letterSpacing: ['0.08em', '0.08em', '0.1em'],
                    }}
                  >
                    {toeName}
                  </Label>
                  <Label
                    sx={{
                      fontSize: ['10px', '10px', '12px', '13px'],
                      whiteSpace: 'nowrap',
                      fontFamily: 'VCR OSD MONO',
                      letterSpacing: '0.1em',
                    }}
                  >
                    {rarity}
                  </Label>
                </Flex>
              </Wrapper>
              <Box
                data-aos={aos}
                data-aos-duration="1500"
                data-aos-anchor-placement="top-bottom"
                sx={{ position: 'absolute', display: 'flex' }}
              >
                <HeadToe head={head} style={style} />
              </Box>
            </ContainerCard>
          ))}
        </Grid>
      </Box>
    </Flex>
  )
}
export default JoinSection
