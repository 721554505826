import { useCallback, useState } from 'react'

import styled from 'styled-components'
import { Button, Box, Heading } from 'theme-ui'
import { Select } from 'theme-ui'

import { useWeb3React } from '@web3-react/core'
import { useAppState } from '../../state'
import buttonBG from '../../assets/redButton.png'
import toes from '../../assets/Toes.png'
import Modal from '../Modal/Modal'
const Wrapper = styled(Box)`
  align-items: flex-end;
  display: flex;
`
const Toes = styled(Box)`
  width: 100%;
  height: 100%;
  background-image: url(${toes});
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: 23rem) {
    background-position: center !important;
  }
`

const ToesSection = ({ setActiveState }: any) => {
  const { user, mintToken } = useAppState()
  const { active } = useWeb3React()
  const [isError, setError] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [isBuyingForm, setBuyingForm] = useState(false)

  const handleChooseQuantity = useCallback(() => {
    if (!user && !active) return setActiveState(true)
    setBuyingForm(true)
  }, [user, active, setActiveState])

  const handlePurchase = (event: any) => {
    event.preventDefault()
    hideModalForm()
    mintToken(quantity).then(({ error }: any) => (error ? setError(true) : null))
  }

  const handleChangeQuantity = (event: any) => {
    setQuantity(event.nativeEvent.target.value)
  }

  const hideModal = () => setError(prevState => !prevState)
  const hideModalForm = () => setBuyingForm(prevState => !prevState)

  return (
    <Wrapper
      sx={{
        height: ['45vh', '45rem', '45rem', '45rem', '50rem'],
        maxWidth: [null, null, null, '1600px'],
        width: '100%',
        alignSelf: [null, null, null, 'center'],
      }}
    >
      <Toes
        sx={{
          width: '100%',
          height: ['70%', '56%', '60%', '75%', '75%'],
          backgroundPosition: ['-6rem', 'center', 'center', 'bottom'],
          maxWidth: [null, null, null, null, '1600px'],
        }}
      />
      <Button
        sx={{
          position: 'absolute',
          left: '50%',
          width: ['356px', '556px', '556px', '556px', '616px'],
          height: ['17%', '13.9%'],
          marginLeft: ['-178px', '-282px', '-282px', '-282px', '-324px'],
          bottom: '-2rem',
          backgroundImage: `url(${buttonBG})`,
          backgroundColor: '#000',
          backgroundSize: 'cover',
          textTransform: 'uppercase',
          fontFamily: 'VCR OSD MONO',
          fontSize: '20px!important',
        }}
        //onClick={handleConnectWallet}
        onClick={handleChooseQuantity}
      >
        dip your toes - MINT NOW
      </Button>
      <Modal active={isError} hideModal={hideModal}>
        <Heading
          as="h2"
          sx={{
            color: 'black',
            fontFamily: 'VCR OSD MONO',
            fontSize: '30px',
            paddingBottom: '2rem',
          }}
        >
          ❌ Transaction not executed
        </Heading>
      </Modal>
      <Modal active={isBuyingForm} hideModal={hideModalForm}>
        <Heading
          as="h2"
          sx={{
            color: 'black',
            fontFamily: 'VCR OSD MONO',
            fontSize: '30px',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          How many TOES want to mint ?
        </Heading>

        <Box as="form" onSubmit={e => handlePurchase(e)}>
          <Select
            name="qty"
            id="qty"
            mb={3}
            sx={{ color: '#000000' }}
            onChange={handleChangeQuantity}
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
          </Select>
          <Button>Done!</Button>
        </Box>
      </Modal>
    </Wrapper>
  )
}
export default ToesSection
