import { Flex, Text, Image, Link } from 'theme-ui'
import styled from 'styled-components'

export const Wrapper = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #161616;
  mix-blend-mode: normal;
  text-align: center;

  padding: 2rem 1rem;
`

export const Title = styled(Text)`
  font-family: VCR OSD Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height */

  color: #ffffff;
`
export const LinkTitle = styled(Link)`
  font-family: VCR OSD Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  color: #ffffff !important;
  /* identical to box height */
  &:hover {
    background: -webkit-linear-gradient(190deg, rgb(241, 41, 84) 0%, rgb(236, 118, 49) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
export const Logo = styled(Image)`
  &:hover {
    transform: translateY(0) scale(1.1);
  }
`
