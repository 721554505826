import toe0 from '../assets/toes/toe0.png'
import toe1 from '../assets/toes/toe1.png'
import toe2 from '../assets/toes/toe2.png'
import toe3 from '../assets/toes/toe3.png'
import toe4 from '../assets/toes/toe4.png'
import toe5 from '../assets/toes/toe5.png'
import toe6 from '../assets/toes/toe6.png'
import toe7 from '../assets/toes/toe7.png'
import toe8 from '../assets/toes/toe8.png'
import toe9 from '../assets/toes/toe9.png'
import ZombieToe from '../assets/join-section/zombieToe.png'
import CuteToe from '../assets/join-section/cuteToe.png'
import lemonHead from '../assets/join-section/lemonNinja.png'
import ChillyToe from '../assets/join-section/chillyToe.png'
import ZombieHead from '../assets/zombieHead.png'
import SmokeHead from '../assets/smokeHead.png'
import PrintHead from '../assets/printHead.png'
import WhiteHead from '../assets/headToe.png'
import bubbles1 from '../assets/roadmap/bubbles1.png'
import bubbles2 from '../assets/roadmap/bubbles2.png'
import bubbles3 from '../assets/roadmap/bubbles3.png'

export const KINGTIDE = 'https://kingtide.la'
export const ETHERSCAN =
  'https://etherscan.io/address/0xb23821C433276DDd283e06bB6ff3E639BE61678b#code'

export const NAV_MENU_ITEMS = [
  {
    label: 'story',
  },
  {
    label: 'rarity',
  },
  {
    label: 'roadmap',
  },
]

export const MENU_IMAGE_ITEMS = [
  {
    alt: 'twitter',
    url: '/static/twitter.png',
    link: 'https://twitter.com/TOES_NFT/',
    filter:
      'invert(50%) sepia(87%) saturate(2246%) hue-rotate(162deg) brightness(96%) contrast(106%)',
  },
  {
    alt: 'discord',
    url: '/static/discord.png',
    link: 'https://discord.gg/pCzv3XeQY7',
    filter:
      'invert(43%) sepia(83%) saturate(2599%) hue-rotate(218deg) brightness(91%) contrast(111%)',
  },
  {
    alt: 'opensea',
    url: '/static/opensea.svg',
    link: 'https://opensea.io/collection/theoneeyedsmiley',
    filter:
      'invert(50%) sepia(87%) saturate(2246%) hue-rotate(162deg) brightness(96%) contrast(106%)',
  },
]

export const ROADMAP_LABELS = [
  {
    title: 'PRE-SALE',
    subtitle: 'Upon sellout',
    content: [
      'Send out stickers to the pre-sale community',
      ' All wallet holders during the pre-sale will be on the list for special airdrops',
    ],
    bubble: {
      img: bubbles1,
      styles: {
        width: '3.5rem',
        top: ['45%'],
        right: ['15%', '10%', '15%', '10%', '8%'],
        display: ['flex', 'flex', 'flex', 'flex'],
      },
    },
  },
  {
    title: 'PINKY TOE',
    subtitle: '25% sold',
    content: [
      'Donate 2.5% of net proceeds to a community wallet that will support emerging artists globally',
      'Send free merch (flip flops) to the first 50 people that own 10 T.O.E.S.',
    ],
    bubble: {
      img: bubbles2,
      styles: {
        width: '8rem',
        top: ['45%'],
        right: ['25%', '23%', '30%', '20%', '14%'],
        display: ['flex', 'flex', 'flex', 'flex'],
      },
    },
  },
  {
    title: 'RING TOE',
    subtitle: '50% sold',
    content: [
      'Donate 5% of net proceeds to a community wallet that will support emerging artists globally',
      'Airdrop a 1 of 1 NFT to the top 10 wallet holders',
    ],
    bubble: {
      img: bubbles1,
      styles: {
        width: '3.5rem',
        top: ['45%'],
        right: ['15%', '10%', '15%', '10%', '8%'],
        display: ['flex', 'flex', 'flex', 'flex'],
      },
    },
  },
  {
    title: 'BIG TOE',
    subtitle: '100% sold',
    content: [
      'Donate 10% of net proceeds to a community wallet that will support emerging artists globally',
      'A % of proceeds from future drops will go towards art programs in developing countries',
      'We will put all holders of bunny ears trait into a raffle where 3 wallets will be selected randomly and air droped a Fluf NFT',
      'We will release a full roadmap and plans for how we are going to connect T.O.E.S. around the world',
    ],
    bubble: {
      img: bubbles3,
      styles: {
        width: '18rem',
        top: ['45%'],
        right: ['50%', '50%', '50%', '25%', '25%'],
        display: ['flex', 'flex', 'flex', 'flex'],
      },
    },
  },
]

export const AVATAR_IMAGES = [toe0, toe1, toe2, toe3, toe4, toe5, toe6, toe7, toe8, toe9]

export const AVATAR_LABELS = [
  {
    avatarName: 'Jordan Sobel',
    jobTitle: 'Creator',
    bioText:
      'Is the head of blockchain development, NFT enthusiast, some would say addict, and was a very early adopter of Ethereum. Jordan spent the last decade in the FinTech industry and was the visionary behind T.O.E.S. “The One Eyed Smiley always was a symbol from my childhood that made me laugh and cheered me up through the harder times. Like any kid, I had my ups and downs. Through my highschool years I had more downs than ups. The One Eyed Smiley was a constant theme throughout those years. I was big into street art and TOES became my artistic alter ego. I would go around tagging one eyed Smileys around Los Angeles and do other things like make paintings, drawings, or sketches incorporating a one eyed smiley which i still have( will dig them up at some point). Now the One Eyed Smiley is a symbol that keeps me in touch with my inner child and reminds me to smile through it”',
    bioConnection: 'Connect with JOSO on twitter @joso8181',
  },
  {
    avatarName: 'Jordan Rothstein',
    jobTitle: 'Creative Director',
    bioText:
      'CEO of King Tide, a digital product and venture studio based in Los Angeles and Mexico city with over 46 designers, engineers and product experts king tide builds products for fortune 500 and start ups alike.',
  },
  {
    avatarName: 'Kenji Ball',
    jobTitle: 'Architect',
    bioText:
      'Kenji is an early adopter and an experienced NFT connoisseur. He is front office at one of the world biggest bookmakers and loves to code. He is the mind behind the scenes helping with everything from building T.O.E.S, dev work, marketing, project planning and much more. He’s passionate about NFTs but more importantly about the community and giving back to T.O.E.S holders and charities.',
  },
  {
    avatarName: 'James Richardson',
    jobTitle: 'Engineer',
    bioText:
      'Is the CTO at King Tide, and oversees the blockchain engineers team. James is also the technical advisors a th Crypto Company ($TCC) and cofounder and CTO at blockchain training alliance. Previously James was the CTO at Crowd Funder and sold his last company to Ebay.',
  },
  {
    avatarName: 'Nitish "FUB" Reddy',
    jobTitle: 'Community Manager',
    bioText:
      'Fub is our community manager hes an active member of the NFT community and one of the projects earliest supporters “I was an avid gamer since my childhood.So you can pretty much say i love my games.I entered the NFT space in the end of august broke af tbh. So in the search for my first NFT , I entered an invite competition where I got one for free and the journey is just about to begin. Joso will always have a special place in my heart as he was the first one to gift me a free NFT ( a derivative of bayc ) and he is always on the hunt to help people out. I joined T.O.E.S to serve the purpose of greater good and to bring more people from different parts of the world to come together to keep shakingggg.”',
  },
  {
    avatarName: 'Marte Baquerizo',
    jobTitle: 'Engineer',
    bioText:
      'Is VP of engineers at King Tide and has over 25 years in experience doing DevOps/ Infrastructure and back end engineering. He has worked in highly technical settings involving government programs, Fintech , and healthcare systems.',
  },
  {
    avatarName: 'Eduardo Zarate',
    jobTitle: 'Engineer',
    bioText:
      'Head of backend development at King Tide and oversees a team of 10 senior engineers. He hosts one of the largest Java meet ups in Mexico City and is a established crypto enthusiast in the community.',
  },
  {
    avatarName: 'Manuel Martinez',
    jobTitle: 'Engineer',
    bioText:
      'Is the head of mobile development at King Tide. He oversees a team of 8 engineers specializing in IOs, Android, React Native , Kubernetes and Python and Unity. Manuel has lots of experience with smart contracts across various projects and has a passion for NFT’s.',
  },
  {
    avatarName: '“M.ssMylestix “EM” - Artist from Laos',
    jobTitle: '',
    bioText: `A young artist ouf of Laos “Em” is very talented and emerging talent out of the South East Asia area. He has a very relatable and charming style to his art taps into the inner child in all of us. “I was interested in crypto and then I heard about NFT and I tried it. NFT is what gives me and my family a better life - and NFT is the way to make profit while covid situation in Laos. I like to draw and most of them are in cartoon style.  But sometimes it's anime style”. You can find more of his work on his OpenSea account `,
    bioConnection: 'https://opensea.io/MylestixEm',
  },
  {
    avatarName: 'Marisol Hernandez',
    jobTitle: 'Developer',
    bioText:
      'Senior front end developer who helps us keep the website up to date and in top shape.',
  },
]

export const AVATAR_BIO_LINKS: string | any = {
  'Connect with JOSO on twitter @joso8181': 'https://twitter.com/joso8181',
  'https://opensea.io/MylestixEm': 'https://opensea.io/MylestixEm',
}

export const TOES_ARMY = [
  {
    aos: 'fade-right',
    toeName: 'BONEHEAD ZOMBIE',
    rarity: 'zombie gang',
    toe: ZombieToe,
    head: ZombieHead,
    style: {
      width: ['3em', '3.5rem', '4rem'],
      left: '0px',
      transform: 'translate(-50%, -40%)',
    },
  },
  {
    aos: 'fade-left',
    toe: CuteToe,
    head: WhiteHead,
    toeName: 'RAINBOW CAT',
    rarity: 'cat gang',
    style: {
      width: ['3rem', '3.5rem', '4rem'],
      bottom: '0',
      right: '0',
      left: '3rem',
      top: ['8rem', '0'],
      transform: 'translate(100%, 20%)',
      '@media screen and (min-width: 639px)': {
        display: 'none',
      },
    },
  },
  {
    aos: 'fade-up',
    toeName: 'KUNG FU LEMONHEAD',
    rarity: 'lemonhead gang',
    head: SmokeHead,
    toe: lemonHead,
    style: {
      width: ['3rem', '3rem', '4rem'],
      left: '0',
      top: ['9.5rem', '9.5rem', '12rem', '15rem'],
      transform: ' translate(-50%, 0%)',
    },
  },
  {
    aos: 'fade-down',
    toeName: 'SAD PANDA',
    rarity: 'panda gang',
    toe: ChillyToe,
    head: PrintHead,
    style: {
      width: ['3rem', '3.5rem', '4rem'],
      left: '0px',
      transform: 'translate(-50%, 64%)',
      bottom: 0,
    },
  },
]

export const RARITY_VARIATIONS = [
  { variation: 'Background', number: 13, bg: true },
  { variation: 'Clothes', number: 48 },
  { variation: 'Skin', number: 23, bg: true },
  { variation: 'Eyes', number: 28 },
  { variation: 'Mouth', number: 31, bg: true },
  { variation: 'Accessories', number: 47 },
]
