import styled from 'styled-components'

export const ModalBlock = styled.div`
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 0.4rem;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  opacity: 1;
  z-index: 400;
`

export const ModalOverlay = styled.a`
  background: rgb(23 23 23 / 75%);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const ModalClose = styled.a`
  float: right !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1rem;
  font-family: VCR OSD MONO;
`

export const ModalContainer = styled.div`
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  max-width: 850px;
  padding: 0 0.8rem;
  width: 100%;
  animation: slide-down 0.2s ease 1;
  z-index: 1;
  box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);
  border: 10px solid #000000;

`

export const ModalHeader = styled.div`
  align-self: end;
  color: #303742;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: space-between;
  padding: 20px 5px 10px 5px;
`

export const ModalBody = styled.div`
  overflow-y: auto;
  padding: 30px 10px;
  position: relative;
  margin-bottom: 3rem;

`

export const ModalFooter = styled.div`
  padding: 10px 0px;
  text-align: right;
`

export const Button = styled.button`
  background: #7b2cbf;
  color: white;
  font-size: 1em;
  margin: 10px;
  padding: 5px 10px;
  border: 2px solid #7b2cbf;
  border-radius: 3px;
  cursor: pointer;
`
