import { createGlobalStyle } from 'styled-components'

import VCR_OSD_MONO from './VCR_OSD_MONO.ttf'

export default createGlobalStyle`
    @font-face {
        font-family: 'VCR OSD MONO';
        src: local('VCR OSD MONO'), local('VCR OSD MONO'),
        url(${VCR_OSD_MONO}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
`
