import { useCallback, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useWeb3React } from '@web3-react/core'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { Link as ScrollLink } from 'react-scroll'
import {
  Box,
  NavLink,
  Avatar,
  Image,
  Button,
  Spinner,
  MenuButton,
  Link,
  useThemeUI,
} from 'theme-ui'
import styled, { css } from 'styled-components'
import theme from '../../theme'
import { useAppState } from '../../state'
import { connectorsByName } from '../../connectors'
import { NAV_MENU_ITEMS, MENU_IMAGE_ITEMS } from '../../utils/constants'
import { Navbar, MenuSection, MenuLink, Item, MenuItem, MenuWrapper, Wrapper } from './HeaderStyles'
import useResponsiveValue from '../../hooks/UseResposive'
import Tooltip from '../Tooltip/Tooltip'
import cuteToe from '../../assets/cuteToe.png'
import logo from '../../assets/Logo.svg'

export type HeaderProps = {
  //
}
const WalletButton = styled(Button)<{ isOnline: any }>`
  ${({ isOnline }) => css`
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    ${!isOnline &&
    ` &:hover {
        box-shadow: 0.2rem 0.2rem 0 #faff20;
        transform: translate(-0.2rem, -0.2rem);
        color: #faff20 !important;
        background-color: black;
        border: 0.5px solid #faff20;
      }`}
  `}
`

const ItemContainer = styled(Item)`
  display: flex;
  align-items: center;
`

const Header = () => {
  const WALLET_LABEL = Object.keys(connectorsByName)[0]
  const history = useHistory()
  let location = useLocation()

  const { activatingConnector, setActivatingConnector } = useAppState()
  const { connector, activate, error, active } = useWeb3React()
  const { user, isAuthenticated } = useAppState()

  const [isOpen, setIsOpen] = useState(false)

  const isOnline = isAuthenticated && user
  const currentConnector = connectorsByName[WALLET_LABEL as keyof typeof connectorsByName]
  const activating = currentConnector === activatingConnector
  const connected = currentConnector === connector
  const {
    theme: { breakpoints = [] },
  } = useThemeUI()

  const value = useResponsiveValue(breakpoints)

  const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen])

  useEffect(() => {
    if (value !== breakpoints[0] && isOpen) setIsOpen(false)
  }, [value, breakpoints, isOpen])

  const handleConnect = useCallback(() => {
    setActivatingConnector(currentConnector)
    activate(connectorsByName[WALLET_LABEL as keyof typeof connectorsByName] as AbstractConnector)
  }, [WALLET_LABEL, currentConnector, activate, setActivatingConnector])

  return (
    <Navbar
      m={3}
      theme={theme}
      sx={{
        width: ['92%', '96%', '96%', '98%'],
        justifyContent: [null, null, null, null, 'center'],
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Wrapper
        theme={theme}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: ['start', 'center', 'center'],
          padding: 2,
          flexWrap: 'wrap',
          minHeight: ['4rem', '6rem', '6rem'],
          maxWidth: [null, null, null, null, '1600px'],
        }}
        isOpen={isOpen}
      >
        <MenuButton
          sx={{
            marginRight: [0, 8, 8],
            display: ['flex', 'none', 'none'],
            alignSelf: ['flex-start', 'normal', 'auto'],
            marginTop: ['0.3rem', 0, 0],
          }}
          aria-label="Toggle Menu"
          onClick={handleOpen}
        />
        <ScrollLink
          className="link"
          activeClass="active"
          to="toes"
          spy={true}
          smooth={true}
          offset={0}
          duration={750}
        >
          <Link
            sx={{
              color: 'white',
              fontSize: [0, 3, 5],
              fontWeight: 'bold',
              mx: [0, 25, 25],
              display: ['none', 'flex', 'flex'],
              cursor: 'pointer',
            }}
            onClick={() => history.push('/')}
          >
            <Image src={logo} alt="logo" sx={{ width: ['0px', '8rem', '9rem'] }} />
          </Link>
        </ScrollLink>

        <MenuSection
          sx={{
            flexDirection: ['column-reverse', 'row', 'row'],
            justifyContent: 'space-between',
            flexWrap: ['wrap', 'nowrap', 'nowrap'],
          }}
        >
          <MenuWrapper
            sx={{
              position: ['fixed', 'relative', 'relative'],
              left: ['2rem', 0, 0],
              top: ['5rem', 0, 0],
              zIndex: [2, 0, 0],
              display: ['block', 'flex', 'flex'],
            }}
          >
            {NAV_MENU_ITEMS.map(({ label }) => (
              <MenuItem
                isOpen={isOpen}
                sx={{
                  display: ['none', 'flex', 'flex'],
                }}
              >
                <ScrollLink
                  className="link"
                  activeClass="active"
                  to={label}
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={750}
                >
                  <MenuLink variant="nav" sx={{ fontSize: [2, 2, '24px'] }}>
                    {label}
                  </MenuLink>
                </ScrollLink>
              </MenuItem>
            ))}

            <Image
              src={logo}
              alt="logo"
              sx={{
                display: ['flex', 'none'],
                width: ['8rem', '8rem', '9rem'],
                position: 'absolute',
                left: '50%',
                height: ['17%', '13.9%'],
                marginLeft: ['4rem', null],
              }}
              onClick={() => history.push('/')}
            />
          </MenuWrapper>
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, flexDirection: ['row'] }}>
            <ItemContainer sx={{ margin: [null, '1rem'] }}>
              {MENU_IMAGE_ITEMS.map(({ alt, url, link, filter }) => (
                <MenuLink
                  onClick={() => window.open(link, '_blank')}
                  sx={{ paddingRight: ['0.8rem', '2rem', '2rem', '2rem', '2rem'] }}
                >
                  <Image
                    sx={{
                      width: ['1.8rem', '34px', '34px', '34px', '34px'],
                      '&:hover': {
                        filter,
                      },
                    }}
                    alt={alt}
                    src={url}
                    width={34}
                    height={27}
                  />
                </MenuLink>
              ))}
              {isOnline && (
                <NavLink
                  sx={{
                    pointerEvents: location.pathname === '/profile' ? 'none' : 'visible',
                    color: location.pathname === '/profile' ? 'green' : 'white',
                  }}
                  onClick={() => history.push('/profile')}
                >
                  <Tooltip label="Profile">
                    <Avatar
                      src={cuteToe}
                      width={38}
                      height={38}
                      sx={{ border: '3px solid white' }}
                    />
                  </Tooltip>
                </NavLink>
              )}
            </ItemContainer>
            <WalletButton
              variant="nav"
              isOnline={isOnline}
              key={WALLET_LABEL}
              sx={{
                borderColor: activating ? 'orange' : connected ? 'green' : 'unset',
                position: 'relative',
                maxWidth: 250,
                fontSize: [1, 1, 3],
                padding: [2, 2, 4],
                margin: [1, 1, 1],
                alignSelf: ['flex-start', 'auto', 'auto'],
                fontFamily: 'VCR OSD MONO',
                backgroundColor: isOnline ? '#8EFF60' : '#FEE72A',
                cursor: 'pointer',
              }}
              onClick={handleConnect}
            >
              {isOnline ? 'Online' : WALLET_LABEL}
              {activating && !error && !active && !isOnline && (
                <Spinner size={20} color="white" sx={{ ml: 1 }} />
              )}
            </WalletButton>
          </Box>
        </MenuSection>
      </Wrapper>
      {/* <UserMenu /> */}
      {/* {isAuthenticated && user && (
        <Flex bg="midGray" py={3} sx={{ justifyContent: 'center' }}>
          <NavLink
            sx={{
              pointerEvents: location.pathname === '/' ? 'none' : 'visible',
              color: location.pathname === '/' ? 'green' : 'white',
            }}
            onClick={() => history.push('/')}
          >
            Marketplace
          </NavLink>
          <Box sx={{ width: 50 }} />
          <NavLink
            sx={{
              pointerEvents: location.pathname === '/profile' ? 'none' : 'visible',
              color: location.pathname === '/profile' ? 'green' : 'white',
            }}
            onClick={() => history.push('/profile')}
          >
            Profile
          </NavLink>
        </Flex>
      )} */}
    </Navbar>
  )
}

export { Header }
