import { SetStateAction, useState } from 'react'
import styled from 'styled-components'
import bubble from '../../assets/bubble.png'
import { Flex, Image, Box, Label, Paragraph, Avatar, Grid } from 'theme-ui'
import { AVATAR_IMAGES } from '../../utils/constants'
import Popup from '../Popup/Popup'
import { PopupText, LinkLabel } from '../Popup/Popup.styles'
import { AVATAR_LABELS, AVATAR_BIO_LINKS } from '../../utils/constants'

const BubbleImg = styled(Image)`
  z-index: 0;
  animation-name: flo;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  position: relative;
  bottom: 80px;
  left:20px;

@keyframes flo{
  50%{
    transform: translateY(30px);
  }
`

const StyledAvatar = styled(Avatar)`
  background-color: #ccc;
  background: rgba(255, 255, 255, 0.1);
  transition: transform ease-out 0.1s, background 0.2s;
  &:hover {
    transform: scale(0.93);
  }
`

const StyledParagraph = styled(Paragraph)`
  padding-bottom: 1rem;
  font-weight: bold !important;
`

const StorySection = () => {
  const [text, setText] = useState(
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
  )
  const [linkBio, setLinkBio] = useState('')

  const [openPopup, setOpenPopup] = useState(false)

  const handlePopup = (bio: SetStateAction<string>, bioConnection: string | any) => {
    setLinkBio(bioConnection)
    setText(bio)
    setOpenPopup(currentState => !currentState)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        padding: ['4rem', '4rem', '4rem'],
        flexDirection: ['column', 'row', 'row'],
        maxWidth: [null, null, null, '1600px'],
      }}
    >
      <Box sx={{ margin: [2, 6, 6], alignSelf: ['center', null, null] }}>
        <BubbleImg sx={{ width: ['15rem', '20rem', '150rem'], left: [null, 0, 0], bottom:[2, null]}} src={bubble} />
      </Box>
      <Flex sx={{ padding: 2, flexDirection: 'column' }}>
        <Label
          sx={{
            fontSize: '48px',
            color: '#FEE72A',
            fontWeight: 'bolder',
            fontFamily: 'VCR OSD MONO',
            textAlign: ['center', null, null],
          }}
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0"
        >
          THE ONE EYED STORY
        </Label>
        <StyledParagraph
          sx={{
            textAlign: ['center', 'left', 'left', 'left', 'left'],
          }}
        >
          T.O.E.S aka The One Eyed Smiley is 10,000 uniquely generated NFTs which bring positivity
          and happiness to the blockchain. Our mission is to bring creatives together from all over
          the world to collaborate on art projects, while making a positive impact in local
          communities. Our first collection was created by enthusiasts in the U.S., designed by a
          young artist from Laos, architected by an engineer in Australia, and deployed by a team of
          developers in Mexico.
        </StyledParagraph>
        <StyledParagraph
          sx={{
            textAlign: ['center', 'left', 'left', 'left', 'left'],
          }}
        >
          T.O.E.S. will cost .025 for 1000 presale and .05 for the remaining. 350 T.O.E.S will be
          reserved for the team and giveaways.
        </StyledParagraph>
        <StyledParagraph
          sx={{
            textAlign: ['center', 'left', 'left', 'left', 'left'],
          }}
        >
          Holding T.O.E.S. grants you copyright and commercial usage, as well as access to future
          membership benefits!
        </StyledParagraph>
        <StyledParagraph
          sx={{
            textAlign: ['center', 'left', 'left', 'left', 'left'],
          }}
        >
          Each piece of art is autogenerated from over 200 unique traits hand-drawn by our artist.
        </StyledParagraph>
        <Grid gap={5} columns={[2, 5, 5]} sx={{ textAlign: 'center' }}>
          {AVATAR_LABELS.map(({ avatarName, jobTitle, bioText, bioConnection }) => (
            <Box sx={{ cursor: 'pointer' }}>
              <StyledAvatar
                sx={{ border: '3px solid white', backgroundColor: 'red' }}
                src={AVATAR_IMAGES[Math.floor(Math.random() * AVATAR_IMAGES.length)]}
                onClick={() => handlePopup(bioText, bioConnection)}
              />
              <Label
                sx={{ fontFamily: 'VCR OSD MONO', fontSize: '12px', justifyContent: 'center' }}
              >
                {avatarName}
              </Label>
              <Label
                sx={{ fontFamily: 'VCR OSD MONO', fontSize: '12px', justifyContent: 'center' }}
              >
                {jobTitle}
              </Label>
            </Box>
          ))}
        </Grid>
      </Flex>
      <Popup show={openPopup} closePopup={() => setOpenPopup(currentState => !currentState)}>
        <PopupText>{text}</PopupText>
        <LinkLabel onClick={() => window.open(AVATAR_BIO_LINKS[linkBio], '_blank')}>
          {linkBio}
        </LinkLabel>
      </Popup>
    </Box>
  )
}
export default StorySection
