import React, { Fragment } from 'react'
import {
  ModalBlock,
  ModalBody,
  ModalClose,
  ModalContainer,
  ModalOverlay,
  ModalHeader,
} from './ModalStyles'

type ModalProps = {
  active?: boolean
  hideModal?: (() => undefined) | any
  children?: JSX.Element | any
}

const Modal = ({ active, hideModal, children }: ModalProps) => {
  return (
    <Fragment>
      {active && (
        <ModalBlock>
          <ModalOverlay onClick={() => hideModal()}></ModalOverlay>
          <ModalContainer>
            <ModalHeader>
              <ModalClose onClick={() => hideModal()}>X</ModalClose>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
          </ModalContainer>
        </ModalBlock>
      )}
    </Fragment>
  )
}
export default Modal
