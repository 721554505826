import * as S from './Popup.styles'

interface PopupProps {
  show: boolean
  closePopup: () => void
  children: any
}

const Popup = ({ show, closePopup, children }: PopupProps) => {
  return (
    <S.Wrapper show={show}>
      <S.PopupOverlay onClick={() => closePopup()} />
      <S.PopupContainer>
        <S.PopupHeader>
          <S.Close onClick={() => closePopup()} aria-label="Toggle dark mode">
            x
          </S.Close>
        </S.PopupHeader>
        <S.PopupBody>{children}</S.PopupBody>
      </S.PopupContainer>
    </S.Wrapper>
  )
}

export default Popup
