import { useState, useEffect, useCallback } from 'react'
import { useThemeUI } from 'theme-ui'

const useResponsiveValue = (array: string[] = []) => {
  const {
    theme: { breakpoints },
  } = useThemeUI()

  const getValue = useCallback(() => {
    let index = breakpoints?.findIndex(
      breakpoint => window.matchMedia(`(max-width: ${breakpoint})`).matches
    )
    if (index === -1) {
      index = (breakpoints?.length || 0) - 1
    }
    return array[index || 0]
  }, [array, breakpoints])

  const [value, setValue] = useState(getValue)

  useEffect(() => {
    const onResize = () => {
      const newValue = getValue()
      if (value !== newValue) {
        setValue(newValue)
      }
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [array, breakpoints, getValue, value])

  return value
}

export default useResponsiveValue
