import { Box, Image } from '@theme-ui/components'
import styled from 'styled-components'
import bgWhite from '../../assets/bg-white.png'

export type ContainerToeProps = {
  toe: string
}

const ContainerCard = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
  max-height: max-content;
  display: inline-block;
  top: 69%;
`

const BgWhite = styled(Image)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0px);
`

const ZombieImg = styled(Image)`
  position: absolute;
  object-fit: contain;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
`

const ContainerToe = ({ toe }: ContainerToeProps) => {
  return (
    <ContainerCard sx={{ width: ['75%'] }}>
      <BgWhite src={bgWhite} />
      <ZombieImg src={toe} />
    </ContainerCard>
  )
}

export default ContainerToe
