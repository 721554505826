import styled from 'styled-components'
import roadMap from '../../assets/RoadmapLine.png'
import roadMap2 from '../../assets/RoadmapLine2.png'
import { Flex, Text, Box, Grid, Heading, Image } from 'theme-ui'
import ribbon from '../../assets/Ribbon.png'
import mobileRibbon from '../../assets/mobileRibbon.png'
import connector from '../../assets/connector.png'
import { ROADMAP_LABELS } from '../../utils/constants'
import bubbles from '../../assets/rarity/bubbles.png'

const Wrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
`

const GradienTitle = styled(Text)`
  font-family: Lucida Console;
  font-weight: bolder;
  font-size: 70px;
  line-height: 68px;
  letter-spacing: 0.08em;
  background-color: #f3ec78;
  text-transform: uppercase;
  background-image: linear-gradient(
    90deg,
    #ff6523,
    #ff6523,
    #ffe72a,
    #ffe82a,
    #a6fe3a,
    #9fff44,
    #39fef0,
    #3affed,
    #df49f7,
    #ff4989
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`

const Ribbon = styled(Box)`
  align-items: center;
  background-image: url(${ribbon});
  background-size: cover;
  display: flex;
  height: 115px;
  justify-content: center;
  position: relative;
  z-index: 1;
`

const RibbonLabel = styled(Text)`
  font-family: monospace;
  font-weight: normal;
  font-size: 36px;
  text-align: right;
  color: #8eff60;
  text-shadow: 0px 0px 4px #8eff60;
`

const TextCard = styled(Box)`
  width: 100%;
  height: auto;
  background: #72d2af;
  color: #0a3737;
  border: 6px solid #f0719e;
  box-shadow: 0px 0px 60px rgba(255, 65, 65, 0.8), inset 0px 0px 20px 3px #000000;
  border-radius: 10px;
  z-index: 2;
`
const RoadMapLine = styled(Image)`
  position: absolute;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 2rem;
`

const RibbonConnector = styled(Image)`
  position: absolute;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 2rem;
`
const Bubbles = styled(Image)`
  position: absolute;
  object-fit: contain;
  width: 2.5rem;
  bottom: 0;
  transform: translate(50%, -50%);
  z-index: -1;
`
const ContentText = styled.li`
  font-weight: 500;
`

const RoadmapSection = () => {
  return (
    <Wrapper
      sx={{
        padding: ['2rem', '10rem', '10rem'],
        justifyContent: 'center',
        maxWidth: [null, null, null, '1600px'],
      }}
    >
      <Box
        sx={{
          marginBottom: '3rem',
          marginRight: ['initial', 'auto', 'auto'],
          alignSelf: ['center', null, null],
        }}
      >
        <GradienTitle
          sx={{ fontFamily: 'VCR OSD MONO', fontSize: ['50px', '70px', '70px'] }}
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0"
        >
          Roadmap
        </GradienTitle>
      </Box>
      <Box>
        <Grid gap={[5, 10, 10]}>
          <RoadMapLine src={roadMap} sx={{ left: '35%', display: ['flex', 'flex', 'none'] }} />
          <RoadMapLine
            src={roadMap2}
            sx={{
              left: ['20%', '20%', '25%', '20%', '30%'],
              display: ['none', 'none', 'flex', 'flex'],
              paddingTop: '5rem',
            }}
          />
          <Grid
            columns={[1, 1, '1fr 3fr']}
            sx={{ placeItems: 'center', rowGap: ['1rem', '1rem', '5rem', '5rem'] }}
          >
            {ROADMAP_LABELS.map(({ title, subtitle, content, bubble }) => (
              <Box
                sx={{
                  display: ['flex', 'contents', 'contents', 'contents', 'contents'],
                  position: 'relative',
                  flexDirection: ['column', 'row'],
                }}
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <RibbonConnector
                  src={connector}
                  sx={{
                    display: ['flex', 'none', 'none', 'none', 'none'],
                    top: '1.78rem',
                    right: '0.6rem',
                  }}
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                />
                <Ribbon
                  sx={{
                    justifySelf: ['flex-start', 'start', 'end'],
                    left: [null, '3rem', '3rem'],
                    width: ['84%', '325px', '325px'],
                    height: ['6rem', null, null, null, null],
                    backgroundImage: [`url(${mobileRibbon})`],
                    backgroundRepeat: ['round'],
                  }}
                  data-aos="zoom-in"
                >
                  <RibbonLabel sx={{ fontFamily: 'VCR OSD MONO' }}>{title}</RibbonLabel>
                </Ribbon>
                <TextCard
                  data-aos="zoom-in"
                  sx={{ placeSelf: 'start', marginTop: ['1rem'], padding: ['1.25rem', '2rem'] }}
                >
                  <Bubbles src={bubble.img} sx={{ ...bubble.styles }} />
                  <Heading sx={{ fontSize: '32px', fontWeight: 'bold', paddingTop: 0 }}>
                    {subtitle}
                  </Heading>
                  {content.map(text => (
                    <ContentText>{text}</ContentText>
                  ))}
                </TextCard>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  )
}
export default RoadmapSection
