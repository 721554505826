import { Flex, Text, Card, Grid, Box, Image } from 'theme-ui'
import styled, { css } from 'styled-components'
import miniToesBG from '../../assets/miniToesBG.png'
import toesGIF from '../../assets/rarity/toes.gif'
import roadMap2 from '../../assets/RoadmapLine2.png'
import bubbles from '../../assets/rarity/bubbles.png'
import rarityRibbon from '../../assets/rarity/ribbon.png'
import mobileRibbon from '../../assets/Ribbon.png'
import { RARITY_VARIATIONS } from '../../utils/constants'
type VariationTypes = {
  bg?: boolean | any
}
const Wrapper = styled(Flex)`
  background-color: #000;
  background-image: url(${miniToesBG});
  background-size: cover;
  width: 100%;
`
const RarityCard = styled(Card)`
  background: #72d2af;
  box-shadow: 0px 0px 20px #f46b9b;
  border: 10px solid #f46b9b;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
`

const TypesBox = styled(Box)<VariationTypes>`
  ${({ bg }) => css`
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    margin-bottom: 0.15rem !important;
    width: 100%;
    height: 40px;
    background: ${bg ? '#F46B9B' : 'transparent'};
    border-radius: 10px;
    color: black;
    font-family: 'VCR OSD MONO';
    font-size: 18px;
    &:hover {
      transform: translateY(0) scale(1.05);
    }
  `}
`

const GifImage = styled.img`
  width: 100%;
  max-width: 30rem;
  border: 25px solid black;
`

const Line = styled(Image)`
  position: absolute;
  object-fit: contain;
  width: 2.8rem;
  bottom: 0;
  transform: translate(-50%, 0);
  filter: invert(78%) sepia(11%) saturate(1526%) hue-rotate(106deg) brightness(82%) contrast(87%);
  z-index: -1;
`

const Bubbles = styled(Image)`
  position: absolute;
  object-fit: contain;
  width: 2.8rem;
  bottom: 0;
  transform: translate(50%, -50%);
  z-index: -1;
`

const RibbonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  background-size: cover;
  position: absolute;
  object-fit: contain;
  bottom: 0;
  z-index: 0;
  left: -5%;
  background-repeat: round;
`

const MobileRibbon = styled(Box)`
  position: absolute !important;
  width: 100%;
  align-items: center;
  background-image: url(${mobileRibbon});
  background-size: cover;
  display: flex;
  height: 115px;
  justify-content: center;
  position: relative;
  z-index: 1;
`

const Categories = () => {
  return (
    <Grid columns={[1, 1, 2, 2, 2]} sx={{ justifyItems: 'center', marginTop: '1rem' }}>
      <Line
        src={roadMap2}
        sx={{
          right: ['20%', '20%', '20%', '20%', '20%'],
          display: ['flex', 'flex', 'flex', 'flex'],
          paddingTop: '2rem',
        }}
      />
      <Bubbles
        src={bubbles}
        sx={{
          top: ['50%', '25%'],
          right: ['8%', '8%', '8%', '8%', '8%'],
          display: ['flex', 'flex', 'flex', 'flex'],
        }}
      />
      <Box sx={{ placeSelf: 'center' }}>
        <GifImage src={toesGIF} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
        <Text
          sx={{
            display: 'flex',
            color: 'black',
            fontSize: '36px',
            width: '16rem',
            fontFamily: 'VCR OSD MONO',
            marginBottom: '1rem',
          }}
        >
          Variations
        </Text>
        <Box sx={{ maxWidth: '30rem' }}>
          {RARITY_VARIATIONS.map(({ variation, number, bg }) => (
            <TypesBox bg={bg ?? false}>
              <Text>{variation}</Text>
              <Text>{number}</Text>
            </TypesBox>
          ))}
          <Text
            sx={{
              color: 'black',
              fontSize: '18px',
              fontStyle: 'italic',
              fontWeight: 'bold',
            }}
          >
            Look out for super rare variations like bunny ears and other fun stuff!
          </Text>
        </Box>
      </Box>
    </Grid>
  )
}

const RaritySection = () => {
  return (
    <Wrapper sx={{ padding: ['2rem', '5rem', '5rem'], justifyContent: 'center' }}>
      <RarityCard
        sx={{
          padding: 6,
          fontFamily: 'monospace',
          maxWidth: '50rem',
          marginTop: ['10rem', '8rem'],
          marginBottom: ['5rem', '8rem'],
        }}
        data-aos="zoom-in"
      >
        <MobileRibbon
          sx={{
            height: '7.5rem',
            backgroundRepeat: 'round',
            top: '-11%',
            left: '0.5rem',
            display: ['flex', 'none'],
          }}
        >
          <Text
            sx={{
              color: '#8EFF60',
              width: '100%',
              padding: '1rem',
              fontSize: ['40px', '60px'],
              fontFamily: 'VCR OSD MONO',
              paddingLeft: [0, '30px', '65px'],
              textAlign: ['center', 'left'],
            }}
          >
            Rarity
          </Text>
        </MobileRibbon>
        <RibbonWrapper
          sx={{
            backgroundImage: [`url(${mobileRibbon})`, `url(${rarityRibbon})`],
            height: ['13%', '12%', '25%', '29%', '29%'],
            width: ['100%', '119%', '116%', '57rem', '57rem'],
            top: ['-12.5%', '-9%', '-25%', '-26%', '-27%'],
            transform: ['translate(10%, 0%)', 'none'],
            display: ['none', 'flex'],
          }}
        >
          <Text
            sx={{
              color: '#8EFF60',
              width: '100%',
              padding: '1rem',
              fontSize: ['40px', '60px'],
              fontFamily: 'VCR OSD MONO',
              paddingLeft: [0, '40px', '65px'],
              textAlign: ['center', 'left'],
            }}
          >
            Rarity
          </Text>
        </RibbonWrapper>
        <Categories />
      </RarityCard>
    </Wrapper>
  )
}
export default RaritySection
