import styled from 'styled-components'
import { Image, ThemeUIStyleObject } from 'theme-ui'

export type HeadToeProps = {
  head: string | undefined
  style: ThemeUIStyleObject | undefined
}

const HeadContainer = styled(Image)`
  position: absolute;
  max-width: none !important;
`
const HeadToe = ({ head, style }: HeadToeProps) => {
  return <HeadContainer src={head} sx={style} />
}

export default HeadToe
