import { utils } from 'ethers'
import { Text, Box, Heading, Grid, Divider, Card, Image, NavLink } from 'theme-ui'
import { Token } from '../components'
import { useAppState } from '../state'
import storyBG from '../assets/storyBG.png'
import DefaultCard from '../components/DeafultCard/DefaultCard'
import styled from 'styled-components'

export type ProfileProps = {}

const ProfileWrapper = styled(Box)`
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  background-color: rgba(30, 14, 46, 0.7);
  padding: 1rem;
  border: 0.5px solid #fff;
  border-radius: 5px;
  &:hover {
    box-shadow: 0.2rem 0.2rem 0 #fff;
    transform: translate(-0.2rem, -0.2rem);
    color: #f0719e !important;
  }
`

const Profile = () => {
  const { user, tokensOnSale, mintToken } = useAppState()

  if (!user) return null

  const { address, balance, ownedTokens } = user

  return (
    <Box
      sx={{
        padding: ['8rem 1rem', '10rem'],
        backgroundImage: `url(${storyBG})`,
        backgroundColor: '#000',
        backgroundSize: 'cover',
      }}
    >
      <ProfileWrapper>
        <Heading
          as="h1"
          sx={{
            fontSize: '35px',
            color: '#faff20',
            fontWeight: 'bolder',
            fontFamily: 'VCR OSD MONO',
          }}
        >
          My Profile
        </Heading>
        <Grid columns={['1fr', '1fr 1fr']} sx={{ overflow: 'hidden', gap: '0 20px' }}>
          <Box>
            <Heading as="h4" sx={{ color: '#72D2AF', fontFamily: 'VCR OSD MONO' }}>
              ADRESS
            </Heading>
            <Text sx={{ overflowWrap: 'anywhere' }}>{address}</Text>
          </Box>
          <Box>
            <Heading as="h4" sx={{ color: '#72D2AF', fontFamily: 'VCR OSD MONO' }}>
              BALANCE
            </Heading>
            <Text>Ξ {balance}</Text>
          </Box>
        </Grid>
      </ProfileWrapper>
      <Divider variant="divider.nft" sx={{ my: 7 }} />
      {/* <Box my={5}>
        <Button
          sx={{
            position: 'absolute',
            left: '50%',
            width: ['356px', '556px', '556px', '556px', '616px'],
            height: ['17%', '13.9%'],
            marginLeft: ['-178px', '-282px', '-282px', '-282px', '-324px'],
            bottom: '-2rem',
            backgroundImage: `url(${buttonBG})`,
            backgroundColor: '#000',
            backgroundSize: 'cover',
            textTransform: 'uppercase',
            fontFamily: 'VCR OSD MONO',
            fontSize: '20px!important',
          }}
          onClick={handleBuyToe}
        >
          dip your toes - MINT NOW
        </Button>
      </Box> */}
      <Box my={5}>
        {ownedTokens && ownedTokens.length > 0 ? (
          <Box>
            <Heading
              as="h2"
              sx={{
                fontFamily: 'VCR OSD MONO',
                margin: '1rem',
                '&:hover': {
                  opacity: 1,
                  color: '#F46B9B',
                },
              }}
            >
              My items{' '}
              <Text variant="text.body" as="span" sx={{ color: '#72D2AF' }}>
                ({ownedTokens.length} item)
              </Text>
            </Heading>
            <Grid gap={6} columns={['1fr', '1fr 1fr 1fr']}>
              {ownedTokens.map((t, index) => (
                <Token
                  isOnSale={
                    !!tokensOnSale?.find(a => utils.formatUnits(a.id) === utils.formatUnits(t.id))
                  }
                  onSale
                  onTransfer
                  token={t}
                  key={index}
                />
              ))}
            </Grid>
          </Box>
        ) : (
          ownedTokens && (
            <Box>
              <Heading as="h2">You don't own any NFT tokens</Heading>
              <DefaultCard />
            </Box>
          )
        )}
      </Box>
    </Box>
  )
}

export { Profile }
