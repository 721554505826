import { Flex, Text, Button, Input, Image } from 'theme-ui'
import styled, { css } from 'styled-components'

export const Wrapper = styled(Flex)`
  font-family: 'VCR OSD MONO';
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20rem;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
`

export const Title = styled(Text)`
  font-family: VCR OSD Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #161616;
`

export const FormEmail = styled(Flex)`
  background: rgba(255, 255, 255, 0.65);
  border: 6px solid #f0719e;
  border-radius: 10px;
  max-width: 769px;
  width: 100%;
  height: 70px;
  margin: 63px 0 0 !important;
`

export const InputEmail = styled(Input)`
  font-family: VCR OSD Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 23px;
  text-align: left;
  padding-left: 24px !important;

  color: #000000;

  background: transparent !important;
  border: none !important;

  &:focus-visible {
    outline: none;
  }
`

export const Submit = styled(Button)`
  font-family: VCR OSD Mono !important;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 35px !important;
  text-align: center;
  color: #8eff60 !important;

  text-shadow: 0px 0px 4px #8eff60;
  width: 47% !important;
`

export const ErrorMessage = styled(Flex)<{ isInvalidEmail: boolean | any }>`
  ${({ isInvalidEmail }) => css`
    padding: 1rem;
    display: ${isInvalidEmail ? 'flex' : 'none!important'};
    color: red;
    animation-name: animation;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    @keyframes animation {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  `}
`
export const ToeImg = styled(Image)`
  width: 1.5rem;
`
export const ModalText = styled(Text)`
  color: black;
  font-size: 28px;
  text-align: center;
  display: flex;
  flex-direction: column;
`
