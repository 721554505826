import * as S from './SignUp.style'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from '@formspree/react'
import WhiteHead from '../../assets/headToe.png'
import ZombieHead from '../../assets/zombieHead.png'
import PrintHead from '../../assets/printHead.png'
import Modal from '../../components/Modal/Modal'
import { Box } from '@theme-ui/components'
import HeadToe from '../JoinSection/HeadToe'

const SignUp = () => {
  const { REACT_APP_FORMSPREE_KEY }: any = process.env
  const [email, setEmail] = useState('')
  const [state, handleSubmit] = useForm(REACT_APP_FORMSPREE_KEY)
  const [isActive, setActiveState] = useState(false)
  const [isInvalidEmail, setIsInvalid] = useState(false)

  useEffect(() => {
    if (state.submitting) setActiveState(true)
  }, [state])

  const handleEmailSubmit = useCallback(() => {
    const validation = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)
    if (validation.test(email)) {
      setIsInvalid(false)
      return handleSubmit({ email })
    }
    setIsInvalid(true)
  }, [email, handleSubmit])

  const hideModal = () => setActiveState(prevState => !prevState)

  return (
    <S.Wrapper sx={{ padding: ['1rem', 0] }}>
      <S.Title sx={{ fontSize: ['18px', '50px'], lineHeight: ['30px', '64px'] }}>
        Sign up to be the first to hear about <strong>T.O.E.S.</strong> News and Events{' '}
      </S.Title>
      <S.FormEmail>
        <S.InputEmail
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
          type="email"
          placeholder="Enter your email..."
        ></S.InputEmail>
        <S.Submit sx={{ fontSize: ['28px!important', '36px'] }} onClick={handleEmailSubmit}>
          SUBMIT
        </S.Submit>
      </S.FormEmail>
      <S.ErrorMessage isInvalidEmail={isInvalidEmail}>
        Please enter a valid email! <S.ToeImg src={WhiteHead} />
      </S.ErrorMessage>
      <Modal active={isActive} hideModal={hideModal}>
        <S.ModalText>Thank you for signing up!</S.ModalText>
        <Box
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="500"
          sx={{ position: 'relative' }}
        >
          <HeadToe
            head={WhiteHead}
            style={{
              width: ['10%', '5%'],
              left: 'auto',
              right: '0',
              bottom: '-1rem',
            }}
          />
          <HeadToe
            head={ZombieHead}
            style={{
              width: ['10%', '5%'],
              left: '0',
              right: '0',
              bottom: '1rem',
            }}
          />
          <HeadToe
            head={PrintHead}
            style={{
              width: ['10%', '5%'],
              left: '60%',
              right: '0',
              bottom: ['4rem', '1.6rem'],
            }}
          />
        </Box>
      </Modal>
    </S.Wrapper>
  )
}

export default SignUp
