import * as S from './Footer.style'
import Logo from '../../assets/logo.png'
import { KINGTIDE, ETHERSCAN } from '../../utils/constants'
import { Box, Link } from '@theme-ui/components'

const Footer = () => {
  return (
    <S.Wrapper sx={{ flexDirection: ['column', 'row'] }}>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <S.Title >T.O.E.S. - A JOSORO PRODUCTION</S.Title>
          <S.Logo sx={{ margin: ['45px'] }} src={Logo} />
          <S.LinkTitle onClick={() => window.open(KINGTIDE, '_blank')}>
            POWERED BY KING TIDE
          </S.LinkTitle>{' '}
        </Box>
        <Link
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#bc71f5',
            fontFamily: 'VCR OSD MONO',
          }}
          onClick={() => window.open(ETHERSCAN, '_blank')}
        >
          TOES Smart Contract
        </Link>
      </Box>
    </S.Wrapper>
  )
}

export default Footer
