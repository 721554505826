import { useEffect, useState, useRef } from 'react'
import { Flex, Box, Image } from 'theme-ui'
import AOS from 'aos'
import styled from 'styled-components'
import ToesSection from '../ToesSection/ToesSection'
import StorySection from '../StorySection/StorySection'
import JoinSection from '../JoinSection/JoinSection'
import RaritySection from '../RaritySection/RaritySection'
import RoadmapSection from '../RoadmapSection/RoadmapSection'
import SignUp from '../SignUpSection/SignUp'
import Footer from '../Footer/Footer'
import { ScrollYAnimation } from '../../utils/scrollYAnimation'
import bottomToesDkt from '../../assets/bottomToesImg.png'
import bottomToes from '../../assets/bottomToes.png'
import storyBG from '../../assets/storyBG.png'
import Modal from '../Modal/Modal'
import { Title } from '../SignUpSection/SignUp.style'
import 'aos/dist/aos.css'

const Wrapper = styled(Flex)`
  background-color: #000;
  background-image: url(${storyBG});
  background-size: cover;
  width: 100%;
`

const Toes = styled(Box)`
  width: 100%;
  height: 100%;
  background-image: url(${bottomToes});
  background-size: cover;
  background-repeat: no-repeat;
`
const Section = styled.section`
  display: flex;
  justify-content: center;
`

const BottomImage = styled(Image)`
  display: flex;
  position: absolute;
  white-space: nowrap;
  z-index: 1;
  color: transparent;
  overflow-x: hidden;
  bottom: 0;
  transform: translateX(-2.5%);
  max-width: unset !important;
`

const Login = () => {
  const toesImgRef = useRef<any>()
  const [isActive, setActiveState] = useState(false)

  useEffect(() => {
    AOS.init()
    AOS.refresh()
    ScrollYAnimation(toesImgRef)
  }, [])

  const hideModal = () => setActiveState(prevState => !prevState)

  return (
    <Flex sx={{ justifyContent: 'center', flexDirection: 'column' }}>
      <Section
        id="toes"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-sine"
        data-aos-delay="500"
        data-aos-offset="0"
        style={{ zIndex: 1 }}
      >
        <ToesSection setActiveState={setActiveState} />
      </Section>
      <Wrapper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: [null, null, null, null, 'center'],
          position: 'relative',
        }}
      >
        <Section id="story">
          <StorySection />
        </Section>
        <Box
          sx={{
            position: 'relative',
            display: ['none', 'flex', 'flex', 'flex', 'flex'],
            height: '15rem',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <BottomImage
            ref={toesImgRef}
            src={bottomToesDkt}
            sx={{ width: [null, '110rem', '110rem', '110rem', '140rem'] }}
          />
        </Box>
        <Toes
          sx={{
            width: '100%',
            height: '12rem',
            backgroundPosition: '-11.5rem',
            display: ['flex', 'none', 'none', 'none', 'none'],
          }}
        />
      </Wrapper>
      <Box sx={{ alignSelf: [null, null, null, null, 'center'], marginBottom: '2rem' }}>
        <JoinSection />
      </Box>
      <Box>
        <Section id="rarity">
          <RaritySection />
        </Section>
      </Box>
      <Box sx={{ backgroundColor: '#161616' }}>
        <Section id="roadmap">
          <Flex sx={{ placeContent: [null, null, null, null, 'center'] }}>
            <RoadmapSection />
          </Flex>
        </Section>
      </Box>
      <Box sx={{ margin: '8.563rem 0 6.438rem' }}>
        <Section id="signup">
          <SignUp />
        </Section>
      </Box>
      <Box>
        <Section id="footer">
          <Footer />
        </Section>
      </Box>
      <Modal active={isActive} hideModal={hideModal}>
        <Title sx={{ display: 'flex', fontSize: '28px', placeContent: 'center!important' }}>
          Ops, Connect with metamask!
        </Title>
      </Modal>
    </Flex>
  )
}

export { Login }
