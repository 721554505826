import styled, { css } from 'styled-components'
import { Box, Button, Text, Paragraph, Link } from 'theme-ui'

export const Wrapper = styled(Box)<{ show?: boolean }>`
  ${({ show }) => css`
    body {
      overflow: ${!show ? 'auto' : 'hidden'};
    }
    display: ${show ? 'flex' : 'none'};
    align-items: center;
    bottom: 0;
    justify-content: center;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    opacity: 1;
    z-index: 400;
    background: rgba(0, 0, 0, 0.9);
    text-align: center;
  `}
`

export const PopupOverlay = styled.a`
  background: rgba(0, 0, 0, 0.9);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
`

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  max-width: 850px;
  padding: 0 0.8rem;
  width: 100%;
  animation: slide-down 0.2s ease 1;
  z-index: 1;
`

export const PopupHeader = styled.div`
  align-self: end;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: space-between;
  padding: 20px 5px 10px 5px;
`

export const PopupBody = styled(Box)`
  padding: 25px 28px 28px 28px;
  text-align: left;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Close = styled(Button)`
  z-index: 400;
  width: 35px !important;
  height: 35px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-appearance: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  line-height: 35px;
  text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  border: 0;
  background: none;
  outline: none;
  box-shadow: none;
  display: inline-block;
  font-family: VCR OSD MONO !important;
`

export const PopupText = styled(Paragraph)`
  max-width: 960px;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: VCR OSD MONO !important;
  font-size: 16px !important;
  line-height: 2.5rem;
`
export const LinkLabel = styled(Link)`
  margin-top: 1rem !important;
  font-family: VCR OSD Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  color: #ffffff !important;
  /* identical to box height */
  &:hover {
    color: #fee72a !important;
  }
`
